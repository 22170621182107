<template>
  <div class="gatewayParam">
    <!-- 参数 -->
    <Head :name="'超级主机'" :add="'/pc/ProGateway'" :adds="'/pc/ProGatewayParam'" :color="false" />
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="paramTit">相关参数</div>
        <div class="paramImg">
          <img src="../../../assets/product/gatewayParam.png" alt class="img" style="height: 1.64rem;"/>
          <div class="imgName">超级主机</div> 
        </div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LSG03A</div>
            </div>
            <div class="li">
              <div class="pasName">产品尺寸:</div>
              <div class="pasCont">100×100×20mm</div>
            </div>
            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">DC5V/2A</div>
            </div>
            <div class="li">
              <div class="pasName">网络接口:</div>
              <div class="pasCont">RJ45</div>
            </div>
            <div class="li">
              <div class="pasName">操作系统:</div>
              <div class="pasCont">Linux</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">工作温度:</div>
              <div class="pasCont">0°C-40°C</div>
            </div>
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
            <div class="li">
              <div class="pasName">通信协议:</div>
              <div class="pasCont">自定义LSSN2.0</div>
            </div>
            <div class="li">
              <div class="pasName">工作频段:</div>
              <div class="pasCont">433.0MHz~453.0MHz</div>
            </div>
            <div class="li">
              <div class="pasName">无线发射功率:</div>
              <div class="pasCont">＜10dBm</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div class="tx">以Linux系统为核心，采用BOT智能物联网控制平台，实现场景模式解析、无线设备控制和云端互联，具备极高的灵活性和定制化能力，大幅度减低布线和安装成本。</div>
          <div class="tx">24小时不间断检测无线设备稳定状态，其核心处理器能快速匹配并连接已对接的智能硬件设备，能对接市面上所有的智能硬件设备产品。</div>
          <div class="tx">采用嵌入式操作系统，硬件配置强大，可单独控制每一个设备，也支持“广播”控制功能，可以广播群发的方式控制多个设备同步动作。</div>
          <div class="tx">支持离线端对端对接，即使网络不通也不影响房内所有智能设备的正常使用。接口支持5G设备的对接，主机覆盖室内建筑面积100-200㎡。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding: 1.2rem 0px;
    .paramTit {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .paramImg {
      margin-top: 0.65rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 2.84rem;
        height: 5.34rem;
      }
      .imgName {
        margin-top: 0.59rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .pas {
      margin-top: 0.81rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 1.21rem;
          }
        }
      }
      .pasRight {
        .li {
          &:last-of-type {
            .pasName {
              margin-right: 0.68rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
       font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
